import React from 'react';
import Categories from './Categories';

const HomePage = () =>
    <div className="App">
    <h1><b>Best Deals Amazon!</b></h1>
    <h5>An Amazon Affiliate</h5>
  

    <main>
      <Categories
        name="Electronics"
        description="Shop Electronics Dept."
        image="/assets/PageLinks/image6.jpeg"
        cat="/pages/Electronics.js"
      />
      <Categories
        name="Toys"
        description="Shop Toys"
        image="/assets/PageLinks/image5.jpeg"
        cat="/pages/Toys.js"
      />
      <Categories
        name="Clothing"
        description="Shop Clothing"
        image="/assets/PageLinks/image4.jpeg"
        cat="/pages/Clothing.js"
      />
      <Categories
        name="Holiday Gifts"
        description="Holiday Gift Ideas"
        image="/assets/PageLinks/minibrandsadvent.jpg"
        cat="/pages/Holiday.js"
      />
      <Categories
        name="Home"
        description="Shop Home Products"
        image="/assets/PageLinks/homeproducts.jpg"
        cat="src/pages/Home.js"
      />
      <Categories
        name="Pet"
        description="Shop Pet Products"
        image="/assets/PageLinks/petproducts.jpg"
        cat="src/pages/Pet.js"
      />
       
       
      {/* Add more products as needed */}
    </main>
  </div>

export default HomePage;