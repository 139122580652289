import React from 'react';

const Product = ({ name, description, image, buyLink, percent }) => (
  <div className="product">
    <img src={image} alt={name} />
    <h2>{name}</h2>
    <h3><b><e><i>{percent}</i></e></b></h3>
    <p>{description}</p>
    <a href={buyLink} target="_blank" rel="noopener noreferrer">
      Buy it now
      </a>
  </div>
);

export default Product;