import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import Clothing from './Clothing';
import HomePage from './HomePage';
import Toys from './Toys';
import Electronics from './Electronics';
import Holiday from './Holiday'
import Home from './Home';
import Pet from './Pet';
import SignUp from './Components/SignUp';
import SignOut from './Components/SignOut';

import Footer from './Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'


function App() {
  const [showDropdown, setShowDropdown] = useState(false)

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const [userEmail, setUserEmail] = useState(null);

  const handleSignUp = (email) => {
    // Set the user's email in state
    setUserEmail(email);
  };


  return (
   
    <Router>
      <div className="App">
    <header className="App-header">
      <img src="/assets/amazonheader.jpeg" alt="Best Deals Amazon" />
    </header>
    <nav>
      <Link to= "/">HomePage</Link>
      <div className= "dropdown" onMouseEnter={toggleDropdown} onMouseLeave={toggleDropdown}>
        <span>Categories</span>
        {showDropdown && (
          <div className="dropdown-content">
     <Link to= "/pages/Holiday.js">Holidays</Link>
      <Link to= "/pages/Electronics.js">Electronics</Link>
      <Link to= "/pages/Toys.js">Toys & Games</Link>
      <Link to= "/pages/Clothing.js">Clothing</Link>
      <Link to= "/pages/Pet.js">Pet</Link>
      <Link to= "/pages/Home.js">Home</Link>
      </div>
        )}
        </div> 
    </nav>
      <main>
        
          <Routes> 
            <Route path="/" element={<HomePage />} />
            <Route path="/pages/Toys.js" element={<Toys />} />
            <Route path="/pages/Clothing.js" element={<Clothing />} />
            <Route path="/pages/Electronics.js" element={<Electronics />} />
            <Route path="/pages/Home.js" element={<Home />} />
            <Route path="/pages/Holiday.js" element={<Holiday />} />
            <Route path="/pages/Pet.js" element={<Pet />} />
            <Route path="/pages/SignUp.js" element={<SignUp />} />
            <Route path="/SignPage.js" exact Component={SignUp} />
            

          </Routes>
        </main>
        
        <SignUp onSignUp={handleSignUp} />
      
      {/* Render the user's email above the footer if available */}
      {userEmail && <p>Welcome, {userEmail}!</p>}
      <SignOut/>
      <ToastContainer />
      <Footer />
      </div>
      <div>
     
    </div>
    </Router>
    
     
  );
}

export default App;