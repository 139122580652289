import React from 'react'
import { auth } from '../firebase';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

const SignOut = () => {
    const handleSignOut = async () => {
        try {
            await auth.signOut();
            toast.success('Successfully Signed Out!')
            console.log('User signed out successfully!');
        }   catch (error) {
            console.error('Error signing out:', error.message);
        }
    };


return (
    <div>
        <button onClick={handleSignOut}>Sign Out</button>
    </div>
)
};

export default SignOut;