import React from 'react';

const Categories = ({ name, description, image, cat, }) => (
  <div className="product">
    <img src={image} alt={name} />
    <h2>{name}</h2>
    <p>{description}</p>
    <a href={cat} target="_blank" rel="noopener noreferrer">
      Shop
      </a>
  </div>
);

export default Categories;