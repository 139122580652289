// SignUp.js
import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { addDoc, collection } from 'firebase/firestore';
import { firestore } from '../firebase';
import './SignUp.css'

const SignUp = ({ onSignUp }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSignUp = async () => {
    const auth = getAuth();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Create a user document in the 'users' collection
      const userCollection = collection(firestore, 'users');
      await addDoc(userCollection, {
        uid: user.uid,
        email: user.email,
        // Add more user data as needed
      });

      console.log('User created successfully!', user);

      // Pass the user's email back to the parent component
      onSignUp(user.email);
    } catch (error) {
      console.error('Error creating user:', error.message);
    }
  };

  return (
    <div className='signup-container'>
      <h2>Sign Up</h2>
      <label>Email:</label>
      <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
      <label>Password:</label>
      <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
      <button onClick={handleSignUp}>Sign Up</button>
    </div>
    
  );
};

export default SignUp;