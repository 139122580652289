import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth'


const firebaseConfig = {
    apiKey: "AIzaSyC1ywZLrAdrmEP7pP1tehQR50WgM4zOMIY",
    authDomain: "affiliate-6f1c5.firebaseapp.com",
    projectId: "affiliate-6f1c5",
    storageBucket: "affiliate-6f1c5.appspot.com",
    messagingSenderId: "1091814955968",
    appId: "1:1091814955968:web:936ba572984e1c4e4a8625",
    measurementId: "G-X70KHQYTF2"
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth(app);

export { firestore, auth };